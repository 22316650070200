<template>
  <router-view></router-view>
</template>

<script>

// import Home from "./components/pages/Home.vue"
//import EntryPage from "./components/pages/entryPage.vue"



export default {
  name: 'App',
  components: {
    // Home,
    // EntryPage
  }
}
</script>

<style lang="scss">

  </style>
