<template>

    <main class="global-container">
      <header class="home-header">
      <a href="/" class="logo-container">
        <img :src="require('../../assets/images/dansuk-logo.png')" alt="Dansuk Logo" class="logo dansuk-logo">
      </a>
      <router-link class="auth-button" to="/authorize">
        <img :src="require('../../assets/images/worker.png')" alt="Dansuk Logo" class="auth-button-img">
        <p>Giriş</p>
      </router-link>
    </header>

    <div class="unauthorized">
        <p>
            Bu sayfayı görüntüleme yetkiniz bulunmuyor.
        </p>
    </div>
   
  
    </main>
    
  
  </template>
  
  <script>
  
  export default {
    name: 'AuthPage',

  }

  </script>
  
  <style lang="scss">
  
    @import "../../styles/core/variables.scss";
    @import "../../styles/core/resets.scss";
    @import "../../styles/core/backgrounds.scss";

    .unauthorized{
        width: 100%;
        height: calc(100vh - $header-height);
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .unauthorized p {
        font-size: 1.7rem;
    }
  

  </style>
  