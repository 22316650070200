<template>
  <footer>
      <div class="footer-divider-container">
            <div class="custom-shape-divider-bottom-1655338748">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div>
      </div>

      <div class="footer-content-container">

            <div class="contact-information">
                <a class="contact-link" href="tel:+902166514545">
                    <img :src="require('../assets/icons/phone.png')" alt="phone">
                    0 216 651 45 45
                </a>
                <a class="contact-link" href="mailto:info@dansuk.com.tr">
                    <img :src="require('../assets/icons/mail.png')" alt="mail">
                    info@dansuk.com.tr
                </a>
            </div>
        
        <div class="footer-logos">
            <img :src="require('../assets/images/dansuk-logo.png')" alt="Dansuk Logo" class="logo dansuk-logo">
        </div>

      </div>

  </footer>
</template>

<script>
export default {
  name: 'acFooter',
}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";


footer{
    height: 25vh;
    background-color: #6e6564;
    margin-top: 20rem;
    border-color: #6e6564;
    width: 100vw;

}

.footer-divider-container{
    position: relative;

}

.footer-content-container{
    padding: 4rem 2rem 0 2rem;
    width:98%;;
    display:flex;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;


}

.contact-information{
    display:flex;
    flex-direction: column;
    align-items: flex-start;

}

a.contact-link{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2rem;
}

a.contact-link img{
    width: 4rem;
    margin-right: 2rem;
}

.contact-information a{
    font-size: 2rem;
    text-align: center;
    font-family: $open-sans;
    font-weight: 300;
    color: white;

}

.footer-content-container img{
    width: 15rem;
    filter: invert(1) brightness(1)
}

.footer-logos{
    display: flex;
    flex-direction: column;

}

.footer-logos > .dansuk-logo{
    width: 15rem;
    margin-bottom: 1rem;
}


  .custom-shape-divider-bottom-1655338748 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1655338748 svg {
    position: relative;
    display: block;
    width: calc(206% + 1.3px);
    height: 15rem;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1655338748 .shape-fill {
    fill: #6e6564;
}

@media screen and (max-width: 700px){

    footer{
        height: unset;
    }
    .footer-content-container{
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .footer-logos{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;
        width:50%;
    }


    .footer-logos > .dansuk-logo{
        width: 8rem;
    }
    .footer-logos > .mesa-logo{
        width: 3rem;
    }
}

</style>
