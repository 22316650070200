import { initializeApp } from "firebase/app";
import '@firebase/firestore'
import { getFirestore } from "firebase/firestore"


const firebaseConfig = {
    apiKey: "AIzaSyBfGGUK7jZLpNji684qK1a0nrX8uO5rxEk",
    authDomain: "dansuk-mesa-ms.firebaseapp.com",
    projectId: "dansuk-mesa-ms",
    storageBucket: "dansuk-mesa-ms.appspot.com",
    messagingSenderId: "512672738261",
    appId: "1:512672738261:web:4d79d92a561d2b418aeb5c"
  };

export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase)
