<template>

  <main class="global-container">
    <header class="home-header">
    <a href="/" class="logo-container">
      <img :src="require('../../assets/images/dansuk-logo.png')" alt="Dansuk Logo" class="logo dansuk-logo">
    </a>
    <router-link class="auth-button" to="/authorize">
      <img :src="require('../../assets/images/worker.png')" alt="Dansuk Logo" class="auth-button-img">
      <p>Giriş</p>
    </router-link>
  </header>
        <div class="projects-container">

            <project-card  v-for="project in projects" :key="project.id" :project="project">
            </project-card>
        
        <div class="logo-row">
          <img :src="require(`../../assets/images/dansuk-logo.png`)">      
        </div>

        </div>

  </main>
  

</template>

<script>

import projectCard from "../projectCard.vue"
import { firestore } from "../../firebase"
import { collection, getDocs } from "firebase/firestore";


export default {
  name: 'EntryPage',
  data(){
    return {
        projects: []
    }
  },

  methods: {
    async fetchProjects(){
        const db = firestore
        const projectRef = collection(db, "projects")
        const querySnapshot = await getDocs(projectRef)
        querySnapshot.forEach((doc) => {
            this.projects.push({
                id: doc.id,
                title: doc.data().title,
                coverImage: doc.data().coverImage.src,
                companyLogo: doc.data().companyLogo.src,
                link: doc.data().link,
                hasBlocks: doc.data().hasBlocks,
            })
        })

        this.projects.sort((a, b) => a.title > b.title ? 1 : -1 )
    },
  },

  components: {
    projectCard
  },

  created(){
    this.fetchProjects()
  }
}
</script>

<style lang="scss">

  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/core/backgrounds.scss";


.home-header{
  width: 100vw;
  height: $header-height;
  display: flex;
  padding: 4rem 2rem;
  align-items: center;
  justify-content: space-between;
  font-family: $open-sans;
  font-size: 1.8rem;
  font-weight: 500;
  background: #003F91
}

.home-header a img{
  width: 15rem;
  filter:invert(1)
}

.auth-button{
  display:flex;
  border: 1.5px solid #e3e3e3;
  border-radius: 5px;
  align-items: center;
  padding: .5rem 1.6rem;
  font-size: 1.8rem;
  color: white;
  font-weight: 300;
}

.auth-button:hover{
  border: 2px solid #e3e3e3;
}

.auth-button img.auth-button-img {
  width: 24px;
  filter: none;
  margin-right: 5px;
}

h1.title{
  font-family:"Exo", sans-serif;
  font-size: 4.4rem;
  text-align: center;
  font-weight: 700;
  color: black;
  margin: 2rem 0 3rem 0;
  grid-column: 1 / -1;
}

.projects-container{
    grid-column: 1 / -1;
    display: grid;
    
    min-height: 100vh;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto);
    background-color: #e3e3e3;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    column-gap: $main-column-gap;
    row-gap: 3rem;
    padding: 2rem 2rem 2rem 2rem;
    
}

@media screen and (max-width: 1440px){
  .projects-container{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px){
  .projects-container{
    display: flex;
    flex-direction: column;
    ;
  }
}

.logo-row{

  grid-column: 1 / -1;
  align-self: end;
  display: flex;
  justify-content: end;


  & img {
    width: 15rem;
  }
}

</style>
