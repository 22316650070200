<template>
    <div class="modular-task">
    <img :src="require(`../assets/icons/${task.icon}.png`)" alt="single task image" class="modular-task__img">
    <div class="modular-task__status">
        <h3 class="modular-task-heading">{{task.title}}</h3>
        <completion-rate-graph :completionRate="determineCompletionRate"></completion-rate-graph>
    </div>
    </div>
</template>

<script>

import completionRateGraph from "./completionRateGraph.vue"

export default {
  name: 'moduleCompletion',
  components: {
    completionRateGraph,
  },
  computed: {
    determineCompletionRate(){
        if(!this.task.isNumeric){
            return this.task.completionRate
        } else if (this.task.isNumeric && this.task.targetItems <= 0){

            // If this condition fires, it means that our team selected this task to be numeric, but they have not (yet?) set a target value. We can not calculate anything without 
            // that information, so we just return zero.

            return 0
        }
        else {
            const percentage = (this.task.completedItems / this.task.targetItems) * 100
            return Math.ceil(percentage)
        }
    }
  },
  props: {
      task: {
          required: false,
      }
  },

}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";

.modular-task{
    width: 100%;
    padding: 2.1rem 2rem;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: flex-start;


    &__img{
        width: 10%;
        margin-right: 1rem;

    }

    &__status{
        width: 100%;
    }

    &-heading{
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
}


</style>
