<template>
    <div class="completion-container__rate">
        
        <div class="completion-container__rate-graph">
            <div :style=" {width: completionRate + '%', backgroundColor: backgroundColor }" class="completion-container__rate-graph-completed"></div>
        </div>
        
        <p class="completion-container__rate-graph-text">%{{completionRate}}</p>
    </div>
</template>

<script>
export default {
    name: "completionRateGraph",
    data(){
        return {
            backgroundColor: null,
        }
    },
    props: ["completionRate"],

    methods: {
        setColor: function(){


            if(this.completionRate < 20){
                this.backgroundColor = "#ED5565"
            } else if(this.completionRate < 50) {
                this.backgroundColor = "#FFCE54"
            } else if(this.completionRate < 60){
                this.backgroundColor = "#ADD468"
            } else if(this.completionRate < 80){
                this.backgroundColor = "#8CC152"
            } else {
                this.backgroundColor = "#089b00"
            }
        }
    },

    mounted(){
        this.setColor()
    },

    updated(){
        this.setColor()
    }

    
}
</script>

<style lang="scss" scoped>

$border-radius: 10px;



    .completion-container__rate{
        display: flex;
        justify-content: space-between;
        align-items: center;

    }


        .completion-container__rate-graph{

            border: 1px solid #a6a6a6;
            border-radius: 10px;
            width: 95%;
            height: 3rem;


            &-completed{
                height: 100%;
                border-radius: $border-radius;
                transition: width 1s;
                transition-timing-function: linear;
                transition-duration: 1s;                
            }

            &-text{
                font-size: 1.6rem;
                font-weight: bold;
                margin-left: 1rem;
            }
        }

        @media screen and (max-width: 1450px){
.completion-container__rate-graph-text{
    margin-left: 1rem;
}

}

</style>