import Vue from 'vue'
import App from './App.vue'
import './index.css'
import VueRouter from "vue-router"
import {routes} from "./routes"

Vue.config.productionTip = false


Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  scrollBehavior(_to, _from, savedPosition){
    if(savedPosition){
      return savedPosition
    } else {
        return {y: 0}
    }
  },
  mode: "history",
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

