<template>
  <header>
    
    <a class="logo-container" :href="logoHref">
      <img :src="logoSrc" alt="project logo" class="logo company-logo">
    </a>
    <nav class="menu">
      <a href="#tasks">İş Listesi</a>
      <a href="#updates">Son Güncellemeler</a>
      <a href="#contact">İletişim</a>
    </nav>
    <a href="https://www.dansuk.com.tr">
      <img :src="require('../assets/images/dansuk-logo.png')" alt="Dansuk Logo" class="logo dansuk-logo">
    </a>
  </header>
</template>

<script>
export default {
  name: 'acHeader',
  props: ["logoSrc", "logoHref"]
}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";


header{
  width: 100vw;
  position:absolute;
  height: $header-height;
  display: flex;
  padding: 4rem;
  align-items: center;
  justify-content: space-between;
  font-family: $open-sans;
  font-size: 1.8rem;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.77);
  backdrop-filter: blur(10px);
}

nav.menu a{
  margin-right: 1.4rem;
  padding: 2rem;
  transition: background-color .2s;

  &:hover{
    background-color: white;
    border-radius:4px;

  }

}

nav.menu a:last-of-type{
  margin-right: 0;
}


a.logo-container{
  max-width: 15rem;
  min-width: 10rem;

}

.company-logo{
  width: 100%;
  object-fit: contain;
  max-height: ($header-height - 2rem);

}

.dansuk-logo{
  width: 11rem;
}

@media screen and (max-width: 1000px){

header{
  top:0;
  left: 0;
}

nav.menu{
  display: none;
}
}


</style>
