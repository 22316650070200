<template>
    <router-link :to="{name: 'home', params: {projectID: project.id}}" class="project-container">
        <img class="project-container__image" :src="project.coverImage" alt="project image">
        <div class="project-container__image-overlay"></div>
        <div class="project-container__title-area">
            {{project.title}}
             <completion-rate-graph class="project-card-graph" :completionRate="completionRate"></completion-rate-graph>
        </div>
    </router-link>
</template>

<script>


import completionRateGraph from "./completionRateGraph.vue"
import { firestore } from "../firebase"
import { doc, getDoc } from "firebase/firestore";

export default {
  name: 'projectCard',
  data(){return {
    completionRate: ""
  }},
  props: {
      project: {
          required: true,
      }
  },

  methods: {
    async getData(){
        const db = firestore
        const projectRef = doc(db, "projects", this.project.id)
        const project = await getDoc(projectRef)
        if(project.exists){
            const completionRef = doc(projectRef, "completion", "completionMeta",)
            const completion = await getDoc(completionRef)
            this.completionRate = completion.data().rate
        } else {
            console.error("Could not fetch data.")
        }
    }
  },
  components:{
    completionRateGraph
  },

  created(){
    this.getData()
  }
}
</script>

<style lang="scss" >


.project-card-graph.completion-container__rate{
    width: 50%;
    margin-left: auto;

    & .completion-container__rate {

        display: block;

        &-graph{
            height: 1rem !important;

            &-completed{
                background-color: white !important;
            }


            &-text{
                font-size: 1.2rem;
            }
        }
    }
}

.project-container{

    display: grid;
    grid-template: 33vh / minmax(20rem,1fr);
    grid-template-areas: "stack";
    cursor: pointer;        
    max-height: 35vh;



    &__image{
        width: 100%;
        object-fit: cover;
        z-index:1;
        grid-area: stack;
        place-self: stretch;
        position: relative;
        z-index:1;
        border-radius: 0 0 1rem 1rem;

    
    &-overlay{
        width: 100%;
        height: 100%;
        grid-area: stack;
        z-index:2;
        background-image: linear-gradient(to right bottom, #d8e1f316, #91919127);
        border-radius: 0 0 1rem 1rem;

        &:hover + .project-container__title-area{
            background-color: #16323b;
        }
    }
    }





    &__title-area{
        grid-area: stack;
        align-self: flex-end;
        justify-self: stretch;
        z-index: 3;
        border-radius: 0 0 1rem 1rem;
        background-color: #16323b80;
        color: #fff;
        font-weight: 300;
        font-size: 1.7rem;
        text-align: end;
        padding: 1.5rem;
        backdrop-filter: blur(6px);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        transition: background-color ease 0.3s;
        cursor: pointer;

        &:hover{
              background-color: #16323b;
        }
    }
}
</style>