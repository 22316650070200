import Home from "./components/pages/Home.vue"
import entryPage from "./components/pages/entryPage.vue"
import AuthPage from "./components/pages/AuthPage.vue"

export const routes = [
    {path: "", name: entryPage, components: {
        default: entryPage, 
    }},

    {path: "/projects/:projectID", name: 'home',
    props: true,
     components: {
        default: Home, 
    }},

    {path: "/authorize", name: 'AuthPage',
     components: {
        default: AuthPage, 
    }},
]