<template>
    <div 
    :style="{backgroundImage: `linear-gradient(to right bottom, rgba(187, 240, 255, 0.3), rgba(0, 108, 41, 0.2)), url('${project.coverImage}')`}"
    class="main-container has-image-bg">
        <ac-header :logoSrc="project.companyLogo" :logoHref="project.link"></ac-header>
        <div class="completion-container">
            <h2 class="completion-container__title">{{project.title}} - Güncel Durum</h2>
            <completion-rate-graph :completionRate="completionRate"/>
                <p class="completion-container__update">
                    {{currentUpdate}}
                </p>
        </div>
    </div>
</template>

<script>

import acHeader from "./acHeader.vue"
import completionRateGraph from "./completionRateGraph.vue"
import { firestore } from "../firebase"
import { doc, getDoc } from "firebase/firestore";

export default {
  name: 'tasksSection',
  data(){
      return {
        completionRate: 0,
        currentUpdate: ""
      }
  },

  props: ["project", "projectID"],

  components: {
      acHeader,
      completionRateGraph,
  },

  methods: {

    async getData(){
        let db = firestore
        const projectRef = doc(db, "projects", this.projectID)
        const project = await getDoc(projectRef)
        if(project.exists){
            const completionRef = doc(projectRef, "completion", "completionMeta")
            const completion = await getDoc(completionRef)
            this.completionRate = completion.data().rate
            this.currentUpdate = completion.data().currentUpdate
        } else {
            console.error("Could not fetch data.")
        }
    },
  },


created(){
    this.getData()
},
  mounted(){
      //this.setCompletionRate()
      
  }
}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";


.has-image-bg{
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
}

.completion-container{
    background: rgba(255, 255, 255, 0.77);
    backdrop-filter: blur(10px);
    grid-column: 4 / 10;
    height: 30vh;
    align-self: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4rem;    
    border-radius: 5px;
    font-family: $open-sans;


    &__title{
        font-size: 2.4rem;
        margin-bottom: 2rem;
        font-weight: 700;
    }

    


    &__update{
        font-family: $open-sans;
        font-size: 1.6rem;
        margin-top: 2rem;
    }
}


@media screen and (max-width: 1000px){

.has-image-bg{
    align-items: center;
    justify-content: center;
    }
}


@media screen and (max-width: 700px){

    .completion-container{
        height:40vh;
    }
}

</style>
