<template>
    <div class="update-container">
        <div class="update-container__meta">
            <h4 class="update-container__meta-date">
                {{update.date}}
            </h4>
        </div>

        <div class="update-container__content">
            <h3 class="update-container__content-title">
                {{update.title}}
            </h3>

            <div v-if="update.images.length > 0" class="update-container__content-image-container">
                 <a v-for="image in update.images" :key="image" :href="image">
                    <img class="update-container__content-image" 
                    :src="image" alt="update image">
                </a>
            </div>


            <p class="update-container__text">
                {{update.text}}
            </p>
            
            <hr class="mb-6 mt-4 w-5/6 mr-auto h-1 bg-gray-100">

        </div>
    </div>
</template>

<script>
export default {
  name: 'update',
  props: {
      update: {
          required: false,
      },

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";

.update-container{
    font-family: $open-sans;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;

    &__content{
        flex-grow: 1;
        flex-basis: 90%;

        &-title{
            font-size: 2.2rem;
            line-height: 1.2;
            font-weight: 600;
            margin-bottom: 1rem;
        }

        &-image-container {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 1.7rem;
            flex-wrap: wrap;
            width: 100%;
            margin: 2rem 0;
        }

        &-image{
            aspect-ratio: 4 / 3;
            object-fit: cover;
            height: 30rem;

        }
    }


    &__meta{

        display: flex;
        flex-direction: column;
        flex-basis: 10%;
        flex-grow: 0;

        &::after{
            content: "";
            background-image: url("../assets/icons/megaphone.png");
            background-size: cover;
            width: 4rem;
            height: 4rem;
            margin-top: 1rem;

        }


        

        &-date{
            font-size: 1.6rem;
            font-weight: 600;
            color: #202020;


        }
    }

    &__text{
        font-size: 1.6rem;
        line-height: 1.55;
        
    }

    &:last-of-type hr{
        display:none;
    }
}

@media screen and (max-width: 700px){

    .update-container{
        flex-direction: column;

    &__text{
        padding-right: 1.6rem;
        
    }

        &__meta{

            &-title{
                font-size: 2.2rem;
                line-height: 1;
                font-weight: 600;
                margin-bottom: 1.6rem;
            }

            &-date{
                font-size: 1.3rem;
            }

            &::after{
                display: none;
            }
        }
    }
}

</style>
