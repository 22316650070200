<template>
  <main class="global-container">
    <completion-section :projectID="getProjectID" :project="projectMetaInformation" id="entry"></completion-section>
    <tasks-section v-if="!isPageLoading" :projectID="getProjectID" :hasBlocks="projectMetaInformation.hasBlocks" id="tasks"></tasks-section>
    <update-section :projectID="getProjectID" id="updates"/>
    <ac-footer id="contact"></ac-footer>
  </main>
  

</template>

<script>

import completionSection from "../completionSection.vue"
import tasksSection from "../tasksSection.vue"
import updateSection from "../updateSection.vue"
import acFooter from "../acFooter.vue"
import { firestore } from "../../firebase"
import { doc, getDoc } from "firebase/firestore";


export default {
  name: 'Home',
  data(){
    return {
        isPageLoading: true,
        projectMetaInformation: {
            companyLogo: "",
            coverImage: "",
            backgroundImage: "",
            hasBlocks: "",
            link: "",
            title: ""
        }
    }
  },

  computed: {
    getProjectID(){
      return this.$route.params.projectID
    }
  },

  props: ["projectID"],

  methods: {
    async fetchProjectDetails(){
        const db = firestore
        const projectRef = doc(db, "projects", this.getProjectID)
        const project = await getDoc(projectRef)
        if(project.exists){
            console.log("running")
            this.projectMetaInformation.companyLogo = project.data().companyLogo.src
            this.projectMetaInformation.coverImage = project.data().coverImage.src
            this.projectMetaInformation.hasBlocks = project.data().hasBlocks,
            this.projectMetaInformation.link = project.data().link
            this.projectMetaInformation.title = project.data().title
        }
    }
  },

  components: {
    tasksSection: tasksSection,
    completionSection: completionSection,
    updateSection: updateSection,
    acFooter:acFooter,
  },

  created(){
    this.fetchProjectDetails().then(() => {
      this.isPageLoading = false
      console.log('page loading is',this.isPageLoading)
    })
  }
}
</script>

<style lang="scss">
  @import "../../styles/core/variables.scss";
  @import "../../styles/core/resets.scss";
  @import "../../styles/core/backgrounds.scss";
  


  
  </style>
