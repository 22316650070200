<template>
    <div class="main-container">
        <h2 class="tasks-section-title">İş Listesi</h2>
        <bms-block-picker @blockChanged="changeSelectedBlock" :blocks="blocks" :selectedBlock="selectedBlock" class="col-start-1 col-end-13 justify-self-center mb-5" v-if="hasBlocks"></bms-block-picker>
        <div class="tasks-container">
            <module-completion v-for="task in tasksList" :key="task.id" :task="task"/>
        </div>
    </div>
</template>

<script>

import moduleCompletion from "./moduleCompletion.vue"
import bmsBlockPicker from "./tools/bmsBlockPicker.vue"
import { firestore } from "../firebase"
import { doc, collection, getDocs } from "firebase/firestore";

export default {
  name: 'tasksSection',
  data(){
      return {
         tasksList: [],
         blocks: [],
         selectedBlock: ""
      }
  },
  props: ["projectID", "hasBlocks"],
  components: {
      moduleCompletion: moduleCompletion,
      bmsBlockPicker,
  },

  methods: {

        fetchTasksOrBlocks(){
            if(this.hasBlocks){
                this.getBlocks()
            } else {
                this.getTasks()
            }
        },

        async getBlocks(){
            console.log("Running get blocks")


            const db = firestore
            const projectRef = doc(db, "projects", this.projectID)
            const querySnapshot = await getDocs(collection(projectRef, "blocks"))
            querySnapshot.forEach((doc) => {

                let currentBlockTasks = []
                
                this.getBlockTasks(this.projectID, doc.id).then((tasks) => {
                    tasks.forEach((task) => {
                        currentBlockTasks.push(task)
                    })
                })

                this.blocks.push({
                    id: doc.id,
                    name: doc.data().name,
                    tasks: currentBlockTasks,
                })
            })


            this.selectedBlock = this.blocks[0]
            this.tasksList = this.selectedBlock.tasks
        },

        async getBlockTasks(projectID, docID){

           
           let tasks = []
           
            const db = firestore
            const blockRef = doc(db, "projects", projectID, "blocks", docID)
            const querySnapshot = await getDocs(collection(blockRef, "tasks"))
            querySnapshot.forEach((doc) => {
                tasks.push({
                    id: doc.id,
                    title: doc.data().title,
                    completionRate: doc.data().completionRate,
                    isNumeric: doc.data().isNumeric ? true : false,
                    targetItems: doc.data().targetItems ? doc.data().targetItems : 0,
                    completedItems: doc.data().completedItems ? doc.data().completedItems : 0,
                    icon: doc.data().icon    
                })
            })
            return tasks
        },

        changeSelectedBlock(block){
            this.selectedBlock = block
            this.tasksList = this.selectedBlock.tasks
            this.tasksList = this.tasksList.sort((a,b) => a.completionRate > b.completionRate ? -1 : 1)

        },

        async getTasks(){
            const db = firestore
            const projectRef = doc(db, "projects", this.projectID)
            const querySnapshot = await getDocs(collection(projectRef, "tasks"))
            querySnapshot.forEach((doc) => {

                this.tasksList.push({
                    id: doc.id,
                    title: doc.data().title,
                    icon: doc.data().icon,
                    completionRate: doc.data().completionRate,
                    isNumeric: doc.data().isNumeric ? true : false,
                    targetItems: doc.data().targetItems ? doc.data().targetItems : 0,
                    completedItems: doc.data().completedItems ? doc.data().completedItems : 0,
                })
            })

            this.tasksList = this.tasksList.sort((a,b) => a.completionRate > b.completionRate ? -1 : 1)

  },
  },

  mounted(){
    this.fetchTasksOrBlocks()
  },

}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";

.tasks-section-title{
    font-family: $noto-sans;
    font-size: 3rem;
    font-weight: 600;
    grid-column: 1 / -1;
    letter-spacing: .015em;
    margin: 4rem 0 1rem 0;
}
.tasks-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: inherit;
    grid-column: 1 / -1;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1400px){
    .tasks-container{
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 700px){

    .tasks-container{
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
    }
}
</style>

