<template>
    <div class="main-container update-section">
        <h2 class="update-section-title">Son Güncellemeler</h2>
        <div class="updates-container">
            <update v-for="update in updatesList" :key="update.id"
            :update="update"
            />
        </div>
    </div>
</template>

<script>

import update from "./update.vue";
import { firestore } from "../firebase"
import { collection, getDocs } from "firebase/firestore";

export default {
  name: 'updateSection',
  data(){
      return {
          updatesList: [],

        }
      },

  props: ["projectID"],


  methods: {
        async getData(){
            const db = firestore
            const updatesRef = collection(db, "projects", this.projectID,  "updates")
            const querySnapshot = await getDocs(updatesRef)
            querySnapshot.forEach((doc) => {
                this.updatesList.push({
                    id: doc.id,
                    title: doc.data().title,
                    text: doc.data().text,
                    images: doc.data().images,
                    date: new Intl.DateTimeFormat('tr-TR').format(new Date(doc.data().date.toDate())),
                })

                this.updatesList.reverse()
            })


  },
  },

      components: {
          update: update,
      },

    created(){
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

@import "../styles/core/variables.scss";


.update-section-title{
    font-family: $noto-sans;
    font-size: 3rem;
    font-weight: 600;
    grid-column: 1 / -1;
    letter-spacing: .015em;
    margin: 4rem 0 1rem 0;
}

.updates-container{
    grid-column: 1 / -1;
}

@media screen and (max-width: 700px){

    .update-section-title{
        margin: 1rem 0 1rem 0;
    }
}

</style>

